.iconBlock img,
img.counterImage {
    height: 4.5rem;
    width: 4.5rem;
}

.ef-newsBlock {
    width: 80%;
}

.ef-socialLinkItem {
    width: 100px;
    float: left;
}

.ef-footerLinkItem,
.ef-topBarLinkItem,
.ef-mainNavLinkItem {
    float: left;
}

.ef-topBarLinks,
.ef-mainNavLinks,
.ef-footerLinks,
.ef-usefulLinks,
.ef-socialLinks {
    padding: 0 15px;
}

.ef-emptyNavFrame {
    height: 30px;
}

#carouselCus2 .g-min-height-70vh {
    min-height: 70vh;
}

.latestNewsDate {
    margin-left: 15px;
}

.body-marginTop {
    margin-top: 11rem !important;
}

#ofacWarningMessage {
    color: #EB0000;
    font-size: 18px;
    visibility: hidden;
}

.u-info-v11-1-img.rounded-circle {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.login-u-btn-primary {
    color: white;
    border-color: #0099cc;
    background-color: #0099cc;
}

.login-u-btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.cookie-banner {
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 5px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #164194;
    background-color: white;
    z-index: 999;
    border-top: #164194 6px solid;
}

.cookie-banner p {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.cookie-banner-close {
    background-color: #164194;
    color: white;
    border: 1px solid #164194;
}

.cookie-banner-close :hover {
    color: #164194;
    background-color: white;
    border: 1px solid #164194;
}


.cookie-banner a {
    text-decoration: underline;
}

.modal-90w {
    width: 90%;
    max-width: none !important;
}

.modal-95w {
    width: 95%;
    max-width: none !important;
}


.itn-subtext {
    font-size:.9em;
}

.itn-client-error {
    color: #EB0000;
}